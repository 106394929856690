import React from "react";
import { Subscribe } from "../components/Subscribe/Subscribe";
import Layout from "../components/Layout";
import Page from "../components/Page";
import Sidebar from "../components/Sidebar";
import { StaticQuery } from "gatsby";

function NewsletterPageRender({ data, isIndex }) {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = data.site.siteMetadata;

  const pageTitle = "Newsletter";
  const description = "";
  return (
    <Layout title={`${pageTitle} - ${siteTitle}`} description={siteSubtitle}>
      <Sidebar />
      <Page title={pageTitle}>
        <p>Subscribe to receive an occassional email from me.  I can't promise it will happen very often, but I'm aiming for something every month.</p>
        <p>My hope is to describe the past month - whether it's travel, photography, birding, learning, or thoughts I'm entertaining.</p>
        <Subscribe />
      </Page>
    </Layout>
  );
}

export const NewsletterPage = props => (
  <StaticQuery
    query={graphql`
      query NewsletterQuery {
        site {
          siteMetadata {
            title
            subtitle
            author {
              name
            }
          }
        }
      }
    `}
    render={data => <NewsletterPageRender {...props} data={data} />}
  />
);

export default NewsletterPage;
