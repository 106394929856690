/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export function Subscribe(props) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(true);

  // can handle the response in two different ways:

  async function handleSubmit(e) {
    e.preventDefault();
    const r = await addToMailchimp(email, { FNAME: name });
    // {"result":"success","msg":"Thank you for subscribing!"}
    setMessage(r.msg);
    if (r.result === 'success') {
      setEmail('');
      setName('');
      setShowForm(false);
      setMessage('Thank you for subscribing!');
    } else {
      if (r.msg.includes('is already subscribed')) {
        setMessage('Already subscribed!');
        return;
      }
      if (r.msg.includes('This email cannot be added to this list')) {
        setMessage(
          'This email cannot be added to this list. Please enter a different email address.          '
        );
        return;
      }
      setMessage('Sorry, I couldn\'t subscribe you with that address');
    }
  }

  function handleEmailChange(event) {
    setEmail(event.currentTarget.value);
  }

  function handleNameChange(event) {
    setName(event.currentTarget.value);
  }

  return (
    <>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            onChange={handleNameChange}
            placeholder="name"
            name="name"
          /><br />
          <input
            type="email"
            onChange={handleEmailChange}
            placeholder="email"
            name="email"
          /><br />
          <input type="submit" value="Subscribe" style={{ color: 'blue' }} />
        </form>
      )}
      <p>{message}</p>
    </>
  );
}
